import React, { useContext } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "./TopBar";
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100dvh",
    overflow: "hidden",
    position: "relative",
    backgroundSize: "cover",
    backgroundColor: "#000",
    backgroundImage: "url(/images/banner_back.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
  },
  root1: {
    height: "100dvh",
    overflow: "hidden",
    position: "relative",
    backgroundSize: "cover",
    backgroundColor: "linear-gradient(180deg, #1B1B1B 0%, #242424 100%)",
    backgroundImage: "url(/images/loginBackground.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    position: "relative",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <Box className="orangeshadeBox1"></Box>
      <Box className="purpleBox1"></Box>
      <TopBar />

      <div className={classes.contentContainer1}>
        <div className={classes.MainLayout}>{children}</div>
      </div>

      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="Accept All Cookies"
        declineButtonText="Reject All"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "linear-gradient(90.27deg, #353535 -0.76%, #1C1C1C 100%)",
        }}
        declineButtonStyle={{
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
          background: "transparent",
          boxShadow:
            "0 1px 0 0 #00A4EF, 0 -1px 0 0 #00A4EF, 1px 0 0 0 #00A4EF, -1px 0 0 0 #00A4EF, 1px -1px 0 0 #00A4EF, -1px 1px 0 0 #00A4EF, 1px 1px 0 0 #00A4EF, -1px -1px 0 0 #00A4EF",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#fff",
            background: "linear-gradient(180deg, #00A4EF 0%, #474747 100%)",
          },
        }}
        buttonStyle={{
          borderRadius: "5px",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
          boxShadow:
            "0 1px 0 0 #00A4EF, 0 -1px 0 0 #00A4EF, 1px 0 0 0 #00A4EF, -1px 0 0 0 #00A4EF, 1px -1px 0 0 #00A4EF, -1px 1px 0 0 #00A4EF, 1px 1px 0 0 #00A4EF, -1px -1px 0 0 #00A4EF",
          backgroundColor: "#00A4EF !important",
          background: "#00A4EF",
        }}
        expires={150}
      >
        <Typography variant="body1" style={{ fontSize: "12px" }}>
          By clicking "Accept All Cookies", you agree to the store the cookies
          on your device for enhancing site navigation, analyzing site usage,
          and assisting in our marketing efforts.&nbsp;
        </Typography>
      </CookieConsent>
    </div>
  );
};

export default MainLayout;
