import _ from "lodash";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiAppBar: {
      positionFixed: {
        // background: "transparent !important",
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "auto",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        padding: "0px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "460px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    paperWidthSm: {
      maxWidth: "600px",
      padding: "20px 30px",
    },
    MuiCollapse: {
      wrapperInner: {
        paddingLeft: "23px",
      },
    },

    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
        borderRight: "1px solid #6B6B6B !important",
        paddingRight: "7px",
        height: "24px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",
        // color: "rgb(255, 125, 104) !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
      },
    },
    MuiPickerDTToolbar: { toolbar: { borderRadius: "8px 8px 0px 0px" } },

    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "rgb(112, 107, 107)",
        },
      },
      contained: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "5px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlined: {
        fontSize: "14px !important",
        fontWeight: "300",
        borderRadius: "50px",
        whiteSpace: "pre",
        padding: "10px 20px",
      },
      outlinedSizeLarge: {
        padding: "7px 35px",
      },
      containedSizeLarge: {},
    },
  },
};

const themesOptions = [
  {
    name: "DARK",
    overrides: {
      MuiMenu: {
        list: {
          outline: "0",
          background:
            "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, #2A2A2A 0%, rgba(0, 0, 0, 0.9) 55.73%, #2A2A2A 100%)",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(75px)",
            zIndex: "-1",
          },
        },
      },

      MuiDialog: {
        paper: {
          padding: "20px 30px",
          background:
            "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, rgba(42, 42, 42, 0.2) 0%, rgba(0, 164, 239, 0.14) 55.73%, rgba(42, 42, 42, 0.2) 100%)",
          boxShadow: "0px 10.4469px 26.1172px rgba(0, 0, 0, 0.03)",
          borderRadius: "0px",
          overflow: "hidden",
          position: "relative",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(75px)",
            zIndex: "-1",
          },
          "@media(max-width:767px)": {
            padding: "10px",
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: "#6B6B6B !important",
        },
        track: {
          backgroundColor: "#E5E5E5",
          opacity: "1",
        },
      },
      MuiPickersDay: {
        day: {
          color: "#fff",
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: { color: "#fff" },
      },
      MuiTableHead: {
        root: {
          background: "#00A4EF",
          borderTop: "1px solid #636262",
          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
      MuiTableBody: {
        root: {
          background:
            "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, rgba(42, 42, 42, 0.2) 0%, rgba(0, 164, 239, 0.14) 55.73%, rgba(42, 42, 42, 0.2) 100%)",
          border: "1px solid #FFFFFF",
          borderTop: "none",
          borderRadius: "8px",
        },
      },
      MuiTableRow: {
        root: {
          "&:hover": {
            backgroundColor: "#ffffff14",
          },
          "&:last-child": {
            borderBottom: "none",
          },
        },
      },
      MuiTableCell: {
        head: {
          padding: "8px 16px",
          fontWeight: "400",
          whiteSpace: "pre",
        },
        body: {
          whiteSpace: "pre",
        },
      },
      MuiInputBase: {
        root: {
          color: "#52565c",
          cursor: "text",
          display: "inline-flex",
          position: "relative",
          background: "#fff",
          boxSizing: "border-box",
          alignItems: "center",
          height: "50px",
          boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
          borderRadius: "7px !important",
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "14px",
          lineHeight: "17px",
          // height: "50px !important",
        },
      },
      MuiSelect: {
        icon: {
          color: "#fff",
        },
      },

      MuiButton: {
        containedPrimary: {
          // background: "#00A4EF",
          backgroundColor: "#00A4EF !important",
          borderRadius: "5px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          marginRight: "10px",
          border: "1px solid transparent",
          "&:hover": {
            color: "#ffffff",
            background: "transparent",
            border: "1px solid #00A4EF",
            backgroundColor: "transparent",
          },
        },
        containedSecondary: {
          borderRadius: "5px",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          height: "45px",
          lineHeight: " 21px",
          padding: "10px 39px",
          marginRight: "10px",

          background: "transparent",
          boxShadow:
            "0 1px 0 0 #00A4EF, 0 -1px 0 0 #00A4EF, 1px 0 0 0 #00A4EF, -1px 0 0 0 #00A4EF, 1px -1px 0 0 #00A4EF, -1px 1px 0 0 #00A4EF, 1px 1px 0 0 #00A4EF, -1px -1px 0 0 #00A4EF",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#fff",
            background: "linear-gradient(180deg, #00A4EF 0%, #474747 100%)",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.025) ",
            color: "#ffffff45",
          },
        },
      },
      MuiPaginationItem: {
        root: {
          color: "#ffffff",
        },
      },
      MuiPaper: {
        root: {
          color: "#fff",
          backgroundColor: "rgba(255, 255, 255, 0.03)",
        },
        // elevation2: {
        //   padding: "40px",
        //   zIndex: "999",
        //   overflow: "hidden",
        //   position: "relative",
        //   background: "rgba(255, 255, 255, 0.04)",
        //   boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.03)",
        //   borderRadius: "0px !important",
        //   "&::before": {
        //     position: "absolute",
        //     backdropFilter: "blur(10px)",
        //     content: "'' ",
        //     height: "100%",
        //     width: "100%",
        //     top: "0",
        //     left: "0",
        //     zIndex: "-1",
        //   },
        //   "@media(max-width:767px)": {
        //     padding: "20px !important",
        //   },
        // },
        elevation2: {
          backgroundColor: "#ffffff05 !important",
          position: "relative",
          zIndex: "999",
          padding: "40px",
          background:
            "linear-gradient(180deg, rgba(143, 143, 143, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(137.09deg, rgba(42, 42, 42, 0.2) 0%, rgba(0, 164, 239, 0.14) 55.73%, rgba(42, 42, 42, 0.2) 100%);",
          overflow: "hidden",
          border: "1px solid #ffffff82",
          boxShadow: "none",
          borderRadius: "8px !important",

          "@media(max-width:767px)": {
            padding: "20px !important",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "#6B6B6B",
        },
      },
      MuiOutlinedInput: {
        inputMultiline: {
          padding: "1px !important",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "none",
          },
        },
        notchedOutline: {
          background: "rgba(255, 255, 255, 0.025)",
          borderColor: "rgba(255, 255, 255, 0.025)",
        },
        input: {
          borderRadius: "10px",
          color: "#6B6B6B",

          padding: "16px 14px ",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#6B6B6B !important",
          },
          "&:-internal-autofill-selected": {
            color: "#6B6B6B !important",
          },
        },
        adornedStart: {
          paddingLeft: "0px !important",
          "& svg": {
            fontSize: "18px",
            color: "#6B6B6B",
          },
        },
      },
    },
    typography: {
      fontFamily: "'K2D', sans-serif",
    },
    palette: {
      background: {
        card: "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        tab: "rgba(255, 255, 255, 0.07)",
        header: "#2A2A2B",
        default:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        price: "#2B2B2B",
        pricename: "#474747",
        blackCard: "#1E1E1E",
        tablehead: "#1B1A1A",
      },
      primary: {
        main: "#ffffff", //black
      },
      secondary: {
        main: "#D9D9D9", //white
        icons: "#FFFFFF", //white
      },
      text: {
        primary: "#FFFFFF", //white
        secondary: "rgba(255, 255, 255, 0.6)", //white
        gray: " #A9A9A9",
        graydark: "#A7A7A7",
        white: "#fff",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
